import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  confirmCheckedOrder,
  confirmOrder,
  confirmShippedOrder,
  detailOrder,
  getItemsOrder,
} from "../../redux/actions/orderActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import LayoutSection from "../../components/LayoutSection";
import { baseURLFile, ReturnStatusValue } from "../../constants";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import InputModel from "../../components/InputModel";

function DetailOrderScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [eventType, setEventType] = useState("");
  const [loadEvent, setLoadEvent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const [confirmImagev, setConfirmImagev] = useState("");
  const [confirmImage, setConfirmImage] = useState("");
  const [confirmImageError, setConfirmImageError] = useState("");

  const [confirmNote, setConfirmNote] = useState("");
  const [confirmNoteError, setConfirmNoteError] = useState("");

  const [preparingWeight, setPreparingWeight] = useState("");
  const [preparingWeightError, setPreparingWeightError] = useState("");

  const [preparingSize, setPreparingSize] = useState("");
  const [preparingSizeError, setPreparingSizeError] = useState("");

  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingNumberError, setTrackingNumberError] = useState("");

  const [trackingService, setTrackingService] = useState("");
  const [trackingServiceError, setTrackingServiceError] = useState("");

  const [shippingPrice, setShippingPrice] = useState(0);
  const [shippingPriceError, setShippingPriceError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const orderDetail = useSelector((state) => state.detailOrder);
  const { successOrderDetail, loadingOrderDetail, order, errorOrderDetail } =
    orderDetail;

  const orderItem = useSelector((state) => state.itemsOrder);
  const { successOrderItems, loadingOrderItems, orderItems, errorOrderItems } =
    orderItem;

  const orderConfirm = useSelector((state) => state.confirmOrder);
  const { loadingOrderConfirm, errorOrderConfirm, successOrderConfirm } =
    orderConfirm;

  const orderConfirmShipped = useSelector((state) => state.confirmShippedOrder);
  const { loadingOrderShipped, errorOrderShipped, successOrderShipped } =
    orderConfirmShipped;

  const orderChecked = useSelector((state) => state.checkedOrder);
  const { loadingOrderChecked, errorOrderChecked, successOrderChecked } =
    orderChecked;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (successOrderConfirm) {
      setLoadEvent(false);
      setEventType("");
      setIsShowConfirm(false);
      setIsOpen(false);
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [successOrderConfirm]);

  useEffect(() => {
    if (successOrderShipped) {
      setLoadEvent(false);
      setEventType("");
      setIsShowConfirm(false);
      setIsOpen(false);
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [successOrderShipped]);

  useEffect(() => {
    if (successOrderChecked) {
      setLoadEvent(false);
      setEventType("");
      setIsShowConfirm(false);
      setIsOpen(false);
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [successOrderChecked]);

  const DateFormat = (dateitem) => {
    // Example date string
    const dateStr = dateitem;

    if (dateStr === "") {
      return "";
    }

    // Create a Date object
    const date = new Date(dateStr);

    // Use Intl.DateTimeFormat to format the date
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);

    return formattedDate;
  };

  const STATUSCOLOR = (status) => {
    if (status === "RETURNED") {
      return "#0000ff";
    } else if (status === "PROCESSING") {
      return "#ffff00";
    } else if (status === "SHIPPED") {
      return "#008000";
    } else if (status === "CANCELED") {
      return "#ff0000";
    } else {
      return "#cdcd1a";
    }
  };

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/orders/">
            <div className="">Orders</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Detail</div>
        </div>
        {order ? (
          <div className="mt-5 mb-1 flex flex-row items-center">
            <div className="font-bold text-2xl mr-2">#{order.order_number}</div>
            <div
              className={`text-xs font-bold text-black border-[0.3px] border-black  ${
                order.status === "RETURNED"
                  ? "bg-primary"
                  : order.status === "PROCESSING"
                  ? "bg-yellow"
                  : order.status === "SHIPPED"
                  ? "bg-green"
                  : order.status === "CANCELED"
                  ? "bg-danger"
                  : "bg-[#cdcd1a]"
              }  mx-2 px-3 rounded-full py-[2px] w-max`}
            >
              {ReturnStatusValue(order.status) ?? ""}
            </div>
          </div>
        ) : null}
        {order ? (
          <div className="mt-1 mb-5">
            {DateFormat(order.date ?? "")} from{" "}
            {order.store_info?.store_name ?? ""}{" "}
          </div>
        ) : null}

        {/*  */}

        {/* <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"> */}
        {/* <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Order Detail N°:{id}
            </h4>
          </div> */}
        {/*  */}
        {loadingOrderDetail ? (
          <Loader />
        ) : errorOrderDetail ? (
          <Alert type="error" message={errorOrderDetail} />
        ) : order ? (
          <div className="flex md:flex-row flex-col my-5 mx-3">
            {/* info items */}
            <div className="md:w-1/2 w-full px-1 py-1">
              <div className="bg-white shadow-1 rounded-md py-5 px-2">
                <div className="text-sm font-bold">
                  {" "}
                  Order Items ({order.items})
                </div>
                <div className="my-5">
                  {loadingOrderItems ? (
                    <></>
                  ) : errorOrderItems ? (
                    <></>
                  ) : (
                    orderItems?.map((item, index) => (
                      <div className="flex flex-row items-center my-2 border-b py-1 border-[#e8dddd]">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-1 rounded"
                          href={
                            item.product_image
                              ? baseURLFile + item.product_image
                              : item.product_image_url
                          }
                        >
                          <img
                            src={
                              item.product_image
                                ? baseURLFile + item.product_image
                                : item.product_image_url
                            }
                            className="w-10 h-10 rounded"
                            alt={item.product_name}
                          />
                        </a>
                        <div className="flex-1 px-2">
                          <p className="text-black  text-sm  font-bold  ">
                            {item.product_name}
                          </p>
                          <p className="text-black  text-xs whitespace-pre-line  ">
                            {item.variation ?? ""}
                          </p>

                          <p className="text-black  text-xs  ">
                            {parseFloat(item.product_price).toFixed(2)}
                          </p>
                          <p className="text-danger  text-xs whitespace-pre-line  ">
                            {item.product_note ?? ""}
                          </p>
                        </div>
                        <div className="px-2">
                          <p className="text-black font-bold  text-sm w-max  ">
                            x {item.quantity}
                          </p>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                <div className="w-full flex  flex-col">
                  {["SUPERADMIN", "ADMIN", "CREATOR"].includes(
                    userInfo.role
                  ) ? (
                    <div className=" w-full flex flex-row">
                      <a
                        href={`/orders/edit/${id}`}
                        className="w-1/2  my-1 mx-1 bg-primary text-white font-bold px-3 py-2 rounded text-center text-xs"
                      >
                        Update
                      </a>
                      <div
                        onClick={() => {}}
                        className="w-1/2  my-1 mx-1 bg-danger text-white font-bold px-3 py-2 rounded  text-center  cursor-pointer text-xs"
                      >
                        Cancel
                      </div>
                    </div>
                  ) : null}
                  {/* "SUPERADMIN","ADMIN","CREATOR","PREPARATOR","SHIPPING","CHECKER", */}
                  <div className=" w-full flex flex-row">
                    {order.status === "PREPARING" &&
                    ["SUPERADMIN", "ADMIN", "CREATOR", "SHIPPING"].includes(
                      userInfo.role
                    ) ? (
                      <div
                        onClick={() => {
                          setEventType("confirm-shipping");
                          setLoadEvent(false);
                          setIsShowConfirm(true);
                        }}
                        className="w-1/2  my-1 mx-1 bg-green text-white font-bold px-3 py-2 rounded  text-center cursor-pointer text-xs"
                      >
                        Confirm Shipping
                      </div>
                    ) : null}
                    {userInfo &&
                    order.status == "PROCESSING" &&
                    ["SUPERADMIN", "ADMIN", "CREATOR", "PREPARATOR"].includes(
                      userInfo.role
                    ) ? (
                      <div
                        onClick={() => {
                          setEventType("confirm-preparing");
                          setConfirmImage("");
                          setConfirmImageError("");
                          setConfirmImagev("");
                          setConfirmNote("");
                          setConfirmNoteError("");
                          setLoadEvent(false);
                          setIsShowConfirm(true);
                        }}
                        className="w-1/2  my-1 mx-1 bg-green text-white font-bold px-3 py-2 rounded  text-center cursor-pointer text-xs"
                      >
                        Confirm Preparing
                      </div>
                    ) : null}
                    {userInfo &&
                    order.is_checked == false &&
                    ["SUPERADMIN", "ADMIN", "CHECKER"].includes(
                      userInfo.role
                    ) ? (
                      <div
                        onClick={() => {
                          setEventType("confirm-checked");

                          setLoadEvent(false);
                          setIsOpen(true);
                        }}
                        className="w-1/2  my-1 mx-1 bg-success text-white font-bold px-3 py-2 rounded  text-center cursor-pointer text-xs"
                      >
                        Confirm Check
                      </div>
                    ) : null}
                    <div className="w-1/2  my-1 mx-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              {/*  */}
              <div className="bg-white shadow-1 rounded-md py-2 my-2 px-2">
                <div className="text-sm font-bold mb-3">Specialized Note</div>

                <div
                  className={`my-2 text-sm ${
                    order.specialized_note && order.specialized_note !== ""
                      ? "bg-[#c3ba05]"
                      : ""
                  }  text-white rounded px-3 py-2 w-full whitespace-pre-line`}
                >
                  {order.specialized_note ?? ""}
                </div>
              </div>
              <div className="bg-white shadow-1 rounded-md py-2 px-2 my-2">
                {/*  */}
                <div className="text-sm font-bold mb-3">Customer</div>
                <div className="my-3 text-sm  px-3  w-full flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>
                  <div className="flex-1">{order.client_name ?? ""}</div>
                </div>
                {/*  */}
                <div className="text-sm font-bold mb-3 mt-3">
                  Contact Information
                </div>
                <div className="my-3 text-sm  px-3 w-full flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                  <div className="flex-1">
                    {order.client_email ?? "No Email"}
                  </div>
                </div>
                <div className="my-3 text-sm  px-3 w-full flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                    />
                  </svg>
                  <div className="flex-1">
                    {order.client_phone ?? "No Phone Number"}
                  </div>
                </div>
                {/*  */}
                <div className="text-sm font-bold mb-3 mt-3">
                  Shipping Address
                </div>
                <button
                  onClick={() => {
                    navigator.clipboard
                      .writeText(order.shipping_address?.address ?? "")
                      .then(() => {
                        toast.success("Address has been copied");
                      })
                      .catch((err) => {
                        toast.error("Failed to copy address: ");
                        console.log(err);
                      });
                  }}
                  className="my-3 text-sm  px-3 w-full flex flex-row hover:text-black-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                    />
                  </svg>
                  <div> {order.shipping_address?.address ?? ""}</div>
                </button>
              </div>
              {/*  */}

              <div className="bg-white shadow-1 rounded-md py-2 px-2 my-2">
                {/*  */}
                <div className="text-sm font-bold mb-3">Order Info</div>
                <div className="my-3 text-sm  px-3 w-full ">
                  Total: <b>{parseFloat(order.total_amount).toFixed(2)}</b>
                </div>
                <div className="my-3 text-sm  px-3  w-full">
                  Type: <b>{order.order_type}</b>
                </div>
                <div className="my-3 text-sm  px-3  w-full">
                  From: <b>{order.order_from}</b>
                </div>

                <div className="my-3 text-sm   px-3 w-full">
                  Order Number: <b>{order.order_number}</b>
                </div>
                <div className="my-3 text-sm  px-3 w-full">
                  Tracking Serivce: <b>{order.tracking_service}</b>
                </div>
                <div className="my-3 text-sm  px-3 w-full">
                  Tracking Number: <b>{order.tracking_number}</b>
                </div>
                <div className="my-3 text-sm  px-3 w-full">
                  Shipping Price:{" "}
                  <b>{parseFloat(order.shipping_price).toFixed(2)}$</b>
                </div>
                <div className="my-3 text-sm  px-3 w-full">
                  Volume: <b>{order.preparing_size}</b>
                </div>
                <div className="my-3 text-sm  px-3 w-full">
                  Poids: <b>{order.preparing_weight} Kg</b>
                </div>
                {order.confirm_image ? (
                  <>
                    <div className="mt-3 text-sm  px-3 w-full">
                      Confirm Image:
                    </div>
                    <div className="mb-3  px-3 ">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-1 rounded "
                        href={baseURLFile + order.confirm_image}
                      >
                        <img
                          src={baseURLFile + order.confirm_image}
                          className="w-20 h-20 rounded bg-black"
                          alt={order.product_name}
                        />
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {/*  */}
          </div>
        ) : null}
        {/* </div> */}
        {isShowConfirm && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-99999 bg-black bg-opacity-20">
            <div className="bg-white shadow-lg rounded mx-3 px-8 pt-6 pb-8 mb-4 md:w-1/3 w-screen">
              <h3 className="text-lg font-bold mb-4">Confirmation</h3>

              <div className="my-3">
                {eventType === "confirm-preparing" ? (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Image"
                      type="file"
                      placeholder=""
                      value={confirmImagev}
                      onChange={(v) => {
                        setConfirmImage(v.target.files[0]);
                        setConfirmImagev(v.target.value);
                      }}
                      error={confirmImageError}
                    />
                  </div>
                ) : null}
                {eventType === "confirm-preparing" ? (
                  <>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Volume"
                        type="text"
                        placeholder=""
                        value={preparingSize}
                        onChange={(v) => {
                          setPreparingSize(v.target.value);
                        }}
                        error={preparingSizeError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Poids (Kg) *"
                        type="number"
                        placeholder=""
                        value={preparingWeight}
                        onChange={(v) => {
                          setPreparingWeight(v.target.value);
                        }}
                        error={preparingWeightError}
                      />
                    </div>
                  </>
                ) : null}
                {eventType === "confirm-shipping" ? (
                  <>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Tracking Service"
                        type="select"
                        placeholder=""
                        value={trackingService}
                        onChange={(v) => {
                          setTrackingService(v.target.value);
                        }}
                        error={trackingServiceError}
                        options={[
                          {
                            value: "DHL",
                            label: "DHL",
                          },
                          {
                            value: "FEDEX",
                            label: "FEDEX",
                          },
                          {
                            value: "ARAMEX",
                            label: "ARAMEX",
                          },
                          {
                            value: "UPS",
                            label: "UPS",
                          },
                          {
                            value: "USPS",
                            label: "USPS",
                          },
                        ]}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Tracking Number *"
                        type="text"
                        placeholder=""
                        value={trackingNumber}
                        onChange={(v) => {
                          setTrackingNumber(v.target.value);
                        }}
                        error={trackingNumberError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Shipping Price *"
                        type="number"
                        isPrice={true}
                        placeholder=""
                        value={shippingPrice}
                        onChange={(v) => {
                          setShippingPrice(v.target.value);
                        }}
                        error={shippingPriceError}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              {/*  */}
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("");
                    setIsOpen(false);
                    setIsShowConfirm(false);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    var check = true;

                    if (
                      eventType === "confirm-shipping" &&
                      trackingNumber === ""
                    ) {
                      setTrackingNumberError("This field is required.");
                      check = false;
                    }

                    if (
                      eventType === "confirm-shipping" &&
                      (shippingPrice === "" || shippingPrice === 0)
                    ) {
                      setShippingPriceError("This field is required.");
                      check = false;
                    }

                    if (
                      eventType === "confirm-preparing" &&
                      preparingWeight === ""
                    ) {
                      setPreparingWeightError("This field is required.");
                      check = false;
                    }

                    if (check) {
                      setIsOpen(true);
                    } else {
                      toast.error("Some fields are mandatory please check");
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 mx-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        <ConfirmationModal
          isOpen={isOpen}
          message={
            eventType === "cancel"
              ? "Are you sure you want to Cancel this Order ?"
              : eventType === "confirm-preparing"
              ? "Are you sure you want to confirm this order for preparation ?"
              : eventType === "confirm-shipping"
              ? "Are you sure you want to confirm this order for shipping ?"
              : eventType === "confirm-checked"
              ? "Are you sure you want to confirm checked this Order?"
              : "Are you sure you want to confirm this Operation?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setLoadEvent(true);
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            } else if (eventType === "confirm-preparing") {
              setLoadEvent(true);

              await dispatch(
                confirmOrder(id, {
                  confirm_image: confirmImage,
                  confirm_note: confirmNote,
                  preparing_weight: preparingWeight,
                  preparing_size: preparingSize,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsShowConfirm(false);
              setIsOpen(false);
            } else if (eventType === "confirm-shipping") {
              setLoadEvent(true);
              await dispatch(
                confirmShippedOrder(id, {
                  tracking_number: trackingNumber,
                  tracking_service: trackingService,
                  shipping_price: shippingPrice,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsShowConfirm(false);
              setIsOpen(false);
            } else if (eventType === "confirm-checked") {
              setLoadEvent(true);
              await dispatch(confirmCheckedOrder(id)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsShowConfirm(false);
              setIsOpen(false);
            } else {
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            }
          }}
          onCancel={() => {
            setIsOpen(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default DetailOrderScreen;

//
