import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getListStores } from "../../redux/actions/storeActions";
import LayoutSection from "../../components/LayoutSection";
import InputModel from "../../components/InputModel";
import { toast } from "react-toastify";
import { addNewOrder } from "../../redux/actions/orderActions";
import { clientList } from "../../redux/actions/clientActions";
import ConfirmationModal from "../../components/ConfirmationModal";
import { productList } from "../../redux/actions/productActions";
import Select from "react-select";
import { baseURLFile } from "../../constants";

function AddOrderScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //
  const [orderType, setOrderType] = useState("NORMAL");
  const [orderTypeError, setOrderTypeError] = useState("");

  const [orderFrom, setOrderFrom] = useState("Morocco");
  const [orderFromError, setOrderFromError] = useState("");

  const [specializedNote, setSpecializedNote] = useState("");
  const [specializedNoteError, setSpecializedNoteError] = useState("");

  const [storeSelect, setStoreSelect] = useState("");
  const [storeSelectError, setStoreSelectError] = useState("");

  const [dateSelect, setDateSelect] = useState("");
  const [dateSelectError, setDateSelectError] = useState("");

  const [orderNumber, setOrderNumber] = useState("");
  const [orderNumberError, setOrderNumberError] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountError, setTotalAmountError] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");

  const [clientPhone, setClientPhone] = useState("");
  const [clientPhoneError, setClientPhoneError] = useState("");

  const [clientEmail, setClientEmail] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");

  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingAddressError, setShippingAddressError] = useState("");

  const [orderItems, setOrderItems] = useState([]);
  const [orderItemsError, setOrderItemsError] = useState("");

  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState("");

  const [productImage, setProductImage] = useState("");
  const [productImagev, setProductImagev] = useState("");
  const [productImageError, setProductImageError] = useState("");

  const [productImageUrl, setProductImageUrl] = useState("");
  const [productImageUrlError, setProductImageUrlError] = useState("");

  const [productQuantity, setProductQuantity] = useState(1);
  const [productQuantityError, setProductQuantityError] = useState("");

  const [productPrice, setProductPrice] = useState(0);
  const [productPriceError, setProductPriceError] = useState("");

  const [productVariation, setProductVariation] = useState("");
  const [productVariationError, setProductVariationError] = useState("");

  const [clientExist, setClientExist] = useState(false);
  const [clientExistError, setClientExistError] = useState(false);

  const [orderClient, setOrderClient] = useState("");
  const [orderClientError, setOrderClientError] = useState("");

  const [productExist, setProductExist] = useState(false);

  const [productSelect, setProductSelect] = useState("");
  const [productSelectError, setProductSelectError] = useState("");
  const [variationList, setVariationList] = useState([]);
  const [variationSelect, setVariationSelect] = useState("");
  const [variationSelectError, setVariationSelectError] = useState("");

  const [productNote, setProductNote] = useState("");
  const [productNoteError, setProductNoteError] = useState("");

  const [fullAddress, setFullAddress] = useState("");
  const [fullAddressError, setFullAddressError] = useState("");

  const [countryAddress, setCountryAddress] = useState("");
  const [countryAddressError, setCountryAddressError] = useState("");

  const [cityAddress, setCityAddress] = useState("");
  const [cityAddressError, setCityAddressError] = useState("");

  const [stateAddress, setStateAddress] = useState("");
  const [stateAddressError, setStateAddressError] = useState("");

  const [zipCodeAddress, setZipCodeAddress] = useState("");
  const [zipCodeAddressError, setZipCodeAddressError] = useState("");

  const [locationXAddress, setLocationXAddress] = useState("");
  const [locationXAddressError, setLocationXAddressError] = useState("");

  const [locationYAddress, setLocationYAddress] = useState("");
  const [locationYAddressError, setLocationYAddressError] = useState("");

  //
  const [isAddOrder, setIsAddOrder] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const listStore = useSelector((state) => state.storeList);
  const { stores } = listStore;

  const addOrder = useSelector((state) => state.createNewOrder);
  const { loadingOrderAdd, errorOrderAdd, successOrderAdd } = addOrder;

  const listClient = useSelector((state) => state.clientList);
  const { clients } = listClient;

  const listProducts = useSelector((state) => state.productList);
  const { products, loadingProductList, errorProductList } = listProducts;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListStores("0"));

      dispatch(clientList("0"));
      dispatch(productList("0"));
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successOrderAdd) {
      setOrderType("NORMAL");
      setOrderFrom("Morocco");
      setStoreSelect("");
      setStoreSelectError("");
      setDateSelect("");
      setDateSelectError("");
      setOrderNumber("");
      setOrderNumberError("");
      setTotalAmount("");
      setTotalAmountError("");

      setClientName("");
      setClientNameError("");
      setClientPhone("");
      setClientPhoneError("");
      setClientEmail("");
      setClientEmailError("");
      setShippingAddress("");
      setShippingAddressError("");
      setClientExist(false);
      setClientExistError("");
      setOrderClient("");
      setOrderClientError("");

      setProductExist(false);
      setProductSelect("");
      setProductSelectError("");
      setVariationSelect("");
      setVariationSelectError("");
      setVariationList([]);
      setProductNote("");
      setProductNoteError("");

      setFullAddress("");
      setFullAddressError("");
      setCountryAddress("");
      setCountryAddressError("");
      setCityAddress("");
      setCityAddressError("");
      setStateAddress("");
      setStateAddressError("");
      setZipCodeAddress("");
      setZipCodeAddressError("");
      setLocationXAddress("");
      setLocationXAddressError("");
      setLocationYAddress("");
      setLocationYAddressError("");
      setSpecializedNote("");
      setSpecializedNoteError("");

      setOrderItems([]);

      setIsAddOrder(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successOrderAdd]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/orders/">
            <div className="">Orders</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}

        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Add new order
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Order Info"
                styles="bg-primary text-white font-bold"
              >
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Type <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <select
                      value={orderType}
                      onChange={(v) => setOrderType(v.target.value)}
                      className={` outline-none border ${
                        orderTypeError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    >
                      <option value={""}>Select Type</option>
                      <option value={"NORMAL"}>Normal</option>
                      <option value={"PROBLEM"}>Problem</option>
                    </select>

                    <div className=" text-[8px] text-danger">
                      {orderTypeError ? orderTypeError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    From <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <select
                      value={orderFrom}
                      onChange={(v) => setOrderFrom(v.target.value)}
                      className={` outline-none border ${
                        orderFromError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    >
                      <option value={""}>Select From</option>
                      <option value={"Morocco"}>Morocco</option>
                      <option value={"Other"}>Other</option>
                    </select>

                    <div className=" text-[8px] text-danger">
                      {orderFromError ? orderFromError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="w-full md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Store <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <Select
                      value={storeSelect}
                      onChange={(option) => {
                        setStoreSelect(option);
                      }}
                      className="text-sm"
                      options={
                        stores &&
                        stores?.map((item) => ({
                          value: item.id,
                          label: item.store_name,
                        }))
                      }
                      filterOption={(option, inputValue) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                      placeholder="Select Store..."
                      isSearchable
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          background: "#fff",
                          border: storeSelectError
                            ? "1px solid #d34053"
                            : "1px solid #E7CCCC",
                          boxShadow: state.isFocused ? "none" : "none",
                          "&:hover": {
                            border: "1px solid #E7CCCC",
                          },
                        }),
                        option: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                        }),
                        singleValue: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                        }),
                      }}
                    />

                    <div className=" text-[8px] text-danger">
                      {storeSelectError ? storeSelectError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Date <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <input
                      type="date"
                      value={dateSelect}
                      onChange={(v) => setDateSelect(v.target.value)}
                      className={` outline-none border ${
                        dateSelectError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {dateSelectError ? dateSelectError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Order Number <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={orderNumber}
                      onChange={(v) => setOrderNumber(v.target.value)}
                      className={` outline-none border ${
                        orderNumberError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {orderNumberError ? orderNumberError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Total Amount <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={totalAmount}
                      onChange={(v) => setTotalAmount(v.target.value)}
                      className={` outline-none border ${
                        totalAmountError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {totalAmountError ? totalAmountError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Specialized Note
                  </div>
                  <div>
                    <textarea
                      value={specializedNote}
                      onChange={(v) => setSpecializedNote(v.target.value)}
                      className={` outline-none border ${
                        specializedNoteError
                          ? "border-danger"
                          : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    ></textarea>

                    <div className=" text-[8px] text-danger">
                      {specializedNoteError ? specializedNoteError : ""}
                    </div>
                  </div>
                </div>
              </LayoutSection>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Customer Info"
                styles="bg-danger text-white font-bold"
              >
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Client Exist
                  </div>
                  <div>
                    <select
                      value={clientExist}
                      onChange={(v) => {
                        setClientExist(v.target.value === "true");
                        setClientEmail("");
                        setClientName("");
                        setClientPhone("");
                      }}
                      className={` outline-none border ${
                        clientExistError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    >
                      <option value={"true"}>Yes</option>
                      <option value={"false"}>No</option>
                    </select>

                    <div className=" text-[8px] text-danger">
                      {clientExistError ? clientExistError : ""}
                    </div>
                  </div>
                </div>

                {clientExist === true ? (
                  <div className="w-full md:pr-1 my-2">
                    <div className="text-black font-bold text-sm  mb-1">
                      Client <strong className="text-danger">*</strong>
                    </div>
                    <div>
                      <Select
                        value={orderClient}
                        onChange={(option) => {
                          setOrderClient(option);
                          console.log(option);

                          setClientEmail("");
                          setClientName("");
                          setClientPhone("");
                          if (option.value !== "") {
                            var filter = clients?.find(
                              (client) =>
                                parseInt(client.id) === parseInt(option.value)
                            );
                            if (filter !== null && filter !== undefined) {
                              setClientEmail(filter.email || "");
                              setClientName(filter.full_name || "");
                              setClientPhone(filter.phone || "");
                            }
                          }
                        }}
                        className="text-sm"
                        options={
                          clients &&
                          clients?.map((item) => ({
                            value: item.id,
                            label: item.full_name,
                          }))
                        }
                        filterOption={(option, inputValue) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                        placeholder="Select Client..."
                        isSearchable
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            background: "#fff",
                            border: orderClientError
                              ? "1px solid #d34053"
                              : "1px solid #E7CCCC",
                            boxShadow: state.isFocused ? "none" : "none",
                            "&:hover": {
                              border: "1px solid #E7CCCC",
                            },
                          }),
                          option: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                          }),
                          singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                          }),
                        }}
                      />

                      <div className=" text-[8px] text-danger">
                        {orderClientError ? orderClientError : ""}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Client Name
                  </div>
                  <div>
                    <input
                      disabled={clientExist}
                      type="text"
                      value={clientName}
                      onChange={(v) => setClientName(v.target.value)}
                      className={` outline-none border ${
                        clientNameError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {clientNameError ? clientNameError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Client Email <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <input
                      type="email"
                      disabled={clientExist}
                      value={clientEmail}
                      onChange={(v) => setClientEmail(v.target.value)}
                      className={` outline-none border ${
                        clientEmailError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {clientEmailError ? clientEmailError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Client Phone
                  </div>
                  <div>
                    <input
                      type="phone"
                      disabled={clientExist}
                      value={clientPhone}
                      onChange={(v) => setClientPhone(v.target.value)}
                      className={` outline-none border ${
                        clientPhoneError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {clientPhoneError ? clientPhoneError : ""}
                    </div>
                  </div>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="w-full px-1 py-1">
              <LayoutSection
                title="Shipping Informations"
                styles="bg-primary text-white font-bold"
              >
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Full Address <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={fullAddress}
                      onChange={(v) => setFullAddress(v.target.value)}
                      className={` outline-none border ${
                        fullAddressError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {fullAddressError ? fullAddressError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Country Address
                  </div>
                  <div>
                    <input
                      type="text"
                      value={countryAddress}
                      onChange={(v) => setCountryAddress(v.target.value)}
                      className={` outline-none border ${
                        countryAddressError
                          ? "border-danger"
                          : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {countryAddressError ? countryAddressError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    City Address
                  </div>
                  <div>
                    <input
                      type="text"
                      value={cityAddress}
                      onChange={(v) => setCityAddress(v.target.value)}
                      className={` outline-none border ${
                        cityAddressError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {cityAddressError ? cityAddressError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    State Address
                  </div>
                  <div>
                    <input
                      type="text"
                      value={stateAddress}
                      onChange={(v) => setStateAddress(v.target.value)}
                      className={` outline-none border ${
                        stateAddressError ? "border-danger" : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {stateAddressError ? stateAddressError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Zip Code Address
                  </div>
                  <div>
                    <input
                      type="text"
                      value={zipCodeAddress}
                      onChange={(v) => setZipCodeAddress(v.target.value)}
                      className={` outline-none border ${
                        zipCodeAddressError
                          ? "border-danger"
                          : "border-[#E7CCCC]"
                      } px-3 py-2 w-full rounded text-sm`}
                    />

                    <div className=" text-[8px] text-danger">
                      {zipCodeAddressError ? zipCodeAddressError : ""}
                    </div>
                  </div>
                </div>

                <div className="md:py-2 md:flex ">
                  <div className="md:w-1/2 w-full  md:pr-1 my-2">
                    <div className="text-black font-bold text-sm  mb-1">
                      Location X
                    </div>
                    <div>
                      <input
                        type="text"
                        value={locationXAddress}
                        onChange={(v) => setLocationXAddress(v.target.value)}
                        className={` outline-none border ${
                          locationXAddressError
                            ? "border-danger"
                            : "border-[#E7CCCC]"
                        } px-3 py-2 w-full rounded text-sm`}
                      />

                      <div className=" text-[8px] text-danger">
                        {locationXAddressError ? locationXAddressError : ""}
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/2 w-full  md:pl-1 my-2">
                    <div className="text-black font-bold text-sm  mb-1">
                      Location Y
                    </div>
                    <div>
                      <input
                        type="text"
                        value={locationYAddress}
                        onChange={(v) => setLocationYAddress(v.target.value)}
                        className={` outline-none border ${
                          locationYAddressError
                            ? "border-danger"
                            : "border-[#E7CCCC]"
                        } px-3 py-2 w-full rounded text-sm`}
                      />

                      <div className=" text-[8px] text-danger">
                        {locationYAddressError ? locationYAddressError : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="w-full px-1 py-1">
              <LayoutSection
                title="Order Items"
                styles="bg-primary text-white font-bold"
              >
                <div className="flex md:flex-row flex-col ">
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Product Exist
                      </div>
                      <div>
                        <select
                          value={productExist}
                          onChange={(v) => {
                            setProductExist(v.target.value === "true");
                            setProductSelect("");
                            setProductSelectError("");
                            setVariationSelect("");
                            setVariationSelectError("");
                            setVariationList([]);
                          }}
                          className={` outline-none border ${"border-[#E7CCCC]"} px-3 py-2 w-full rounded text-sm`}
                        >
                          <option value={"true"}>Yes</option>
                          <option value={"false"}>No</option>
                        </select>

                        <div className=" text-[8px] text-danger"></div>
                      </div>
                    </div>
                    {/*  */}

                    {productExist === true ? (
                      <div className="w-full md:pr-1 my-2">
                        <div className="text-black font-bold text-sm  mb-1">
                          Product <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <Select
                            value={productSelect}
                            onChange={(option) => {
                              setProductSelect(option);
                              console.log(option);

                              if (option.value !== "") {
                                var filter = products?.find(
                                  (product) =>
                                    parseInt(product.id) ===
                                    parseInt(option.value)
                                );
                                console.log(filter);

                                if (filter !== null && filter !== undefined) {
                                  setVariationList(filter.variation_list);
                                  setProductName(filter.product_name);
                                  setProductImageUrl(
                                    filter.product_image
                                      ? baseURLFile + filter.product_image
                                      : filter.product_image_url
                                      ? filter.product_image_url
                                      : ""
                                  );
                                  if (filter.is_variation) {
                                    setProductPrice(0);
                                  } else {
                                    setProductPrice(filter.product_price);
                                  }
                                }
                              }
                            }}
                            className="text-sm"
                            options={
                              products &&
                              products?.map((item) => ({
                                value: item.id,
                                label: item.product_name,
                              }))
                            }
                            filterOption={(option, inputValue) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            placeholder="Select Product..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: productSelectError
                                  ? "1px solid #d34053"
                                  : "1px solid #E7CCCC",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #E7CCCC",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />

                          <div className=" text-[8px] text-danger">
                            {productSelectError ? productSelectError : ""}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/*  */}
                    {productExist === true && variationList.length !== 0 ? (
                      <div className="w-full md:pr-1 my-2">
                        <div className="text-black font-bold text-sm  mb-1">
                          Variation <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <Select
                            value={variationSelect}
                            onChange={(option) => {
                              setVariationSelect(option);
                              console.log(option);

                              if (option.value !== "") {
                                var filter = variationList?.find(
                                  (variation) =>
                                    parseInt(variation.id) ===
                                    parseInt(option.value)
                                );
                                console.log(filter);

                                if (filter !== null && filter !== undefined) {
                                  setProductVariation(filter.variation_price);
                                  setProductVariation(filter.variation_name);
                                  setProductPrice(filter.variation_price);
                                }
                              }
                            }}
                            className="text-sm"
                            options={
                              variationList &&
                              variationList?.map((item) => ({
                                value: item.id,
                                label:
                                  item.variation_name +
                                  " (" +
                                  parseFloat(item.variation_price).toFixed(2) +
                                  ")",
                              }))
                            }
                            filterOption={(option, inputValue) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            placeholder="Select Product..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: variationSelectError
                                  ? "1px solid #d34053"
                                  : "1px solid #E7CCCC",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #E7CCCC",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />

                          <div className=" text-[8px] text-danger">
                            {variationSelectError ? variationSelectError : ""}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/*  */}
                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Image
                      </div>
                      <div>
                        <input
                          disabled={productExist}
                          type="file"
                          value={productImagev}
                          onChange={(v) => {
                            setProductImage(v.target.files[0]);
                            setProductImagev(v.target.value);
                          }}
                          className={` outline-none border ${
                            productImageError
                              ? "border-danger"
                              : "border-[#E7CCCC]"
                          } px-3 py-2 w-full rounded text-sm`}
                        />

                        <div className=" text-[8px] text-danger">
                          {productImageError ? productImageError : ""}
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Image Url
                      </div>
                      <div>
                        <input
                          disabled={productExist}
                          type="text"
                          value={productImageUrl}
                          onChange={(v) => {
                            setProductImageUrl(v.target.value);
                          }}
                          className={` outline-none border ${
                            productImageUrlError
                              ? "border-danger"
                              : "border-[#E7CCCC]"
                          } px-3 py-2 w-full rounded text-sm`}
                        />

                        <div className=" text-[8px] text-danger">
                          {productImageUrlError ? productImageUrlError : ""}
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Product Name <strong className="text-danger">*</strong>
                      </div>
                      <div>
                        <input
                          disabled={productExist}
                          type="text"
                          value={productName}
                          onChange={(v) => {
                            setProductName(v.target.value);
                          }}
                          className={` outline-none border ${
                            productNameError
                              ? "border-danger"
                              : "border-[#E7CCCC]"
                          } px-3 py-2 w-full rounded text-sm`}
                        />

                        <div className=" text-[8px] text-danger">
                          {productNameError ? productNameError : ""}
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Product Price <strong className="text-danger">*</strong>
                      </div>
                      <div>
                        <input
                          disabled={productExist}
                          type="number"
                          min={0}
                          step={0.01}
                          value={productPrice}
                          onChange={(v) => {
                            setProductPrice(v.target.value);
                          }}
                          className={` outline-none border ${
                            productPriceError
                              ? "border-danger"
                              : "border-[#E7CCCC]"
                          } px-3 py-2 w-full rounded text-sm`}
                        />

                        <div className=" text-[8px] text-danger">
                          {productPriceError ? productPriceError : ""}
                        </div>
                      </div>
                    </div>

                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Quantity <strong className="text-danger">*</strong>
                      </div>
                      <div>
                        <input
                          type="number"
                          min={1}
                          step={1}
                          value={productQuantity}
                          onChange={(v) => {
                            setProductQuantity(v.target.value);
                          }}
                          className={` outline-none border ${
                            productQuantityError
                              ? "border-danger"
                              : "border-[#E7CCCC]"
                          } px-3 py-2 w-full rounded text-sm`}
                        />

                        <div className=" text-[8px] text-danger">
                          {productQuantityError ? productQuantityError : ""}
                        </div>
                      </div>
                    </div>

                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Variation
                      </div>
                      <div>
                        <textarea
                          disabled={productExist}
                          value={productVariation}
                          onChange={(v) => {
                            setProductVariation(v.target.value);
                          }}
                          className={` outline-none border ${
                            productVariationError
                              ? "border-danger"
                              : "border-[#E7CCCC]"
                          } px-3 py-2 w-full rounded text-sm`}
                        ></textarea>

                        <div className=" text-[8px] text-danger">
                          {productVariationError ? productVariationError : ""}
                        </div>
                      </div>
                    </div>
                    <div className=" w-full  md:pr-1 my-2">
                      <div className="text-black font-bold text-sm  mb-1">
                        Product Note
                      </div>
                      <div>
                        <textarea
                          value={productNote}
                          onChange={(v) => {
                            setProductNote(v.target.value);
                          }}
                          className={` outline-none border ${
                            productNoteError
                              ? "border-danger"
                              : "border-[#E7CCCC]"
                          } px-3 py-2 w-full rounded text-sm`}
                        ></textarea>

                        <div className=" text-[8px] text-danger">
                          {productNoteError ? productNoteError : ""}
                        </div>
                      </div>
                    </div>

                    <div className="md:py-2 flex ">
                      <button
                        onClick={() => {
                          var check = true;
                          setProductImageError("");
                          setProductImageUrlError("");
                          setProductNameError("");
                          setProductPriceError("");
                          setProductQuantityError("");
                          setProductVariationError("");
                          setProductSelectError("");
                          setVariationSelectError("");
                          setProductNoteError("");

                          if (
                            productExist &&
                            (productSelect === "" || productSelect.value === "")
                          ) {
                            setProductSelectError("This field is required.");
                            check = false;
                          }

                          if (
                            productExist &&
                            variationList.length !== 0 &&
                            (variationSelect === "" ||
                              variationSelect.value === "")
                          ) {
                            setVariationSelectError("This field is required.");
                            check = false;
                          }

                          if (
                            !productExist &&
                            productImage === "" &&
                            productImageUrl === ""
                          ) {
                            setProductImageError("This field is required.");
                            check = false;
                          }
                          if (productName === "") {
                            setProductNameError("This field is required.");
                            check = false;
                          }

                          if (productQuantity === "") {
                            setProductQuantityError("This field is required.");
                            check = false;
                          } else {
                            if (
                              isNaN(Number(productQuantity)) ||
                              !Number.isInteger(Number(productQuantity)) ||
                              Number(productQuantity) <= 0
                            ) {
                              setProductQuantityError(
                                "The value of this field is not a positive number."
                              );
                              check = false;
                            }
                          }

                          if (productPrice === "") {
                            setProductPriceError("This field is required.");
                            check = false;
                          } else {
                            if (
                              isNaN(Number(productPrice)) ||
                              Number(productPrice) <= 0
                            ) {
                              setProductPriceError(
                                "The value of this field is not a positive number."
                              );
                              check = false;
                            }
                          }

                          if (check) {
                            const newItem = {
                              product_name: productName,
                              product_image: productExist
                                ? productImagev
                                : productImage,
                              product_image_url: productImageUrl,
                              product_price: productPrice,
                              product_quantity: productQuantity,
                              product_variation: productVariation,
                              product_exist: productExist,
                              product_select: productSelect.value,
                              variation_select: variationSelect.value,
                              product_note: productNote,
                            };
                            console.log(newItem);

                            setOrderItems([...orderItems, newItem]);

                            console.log(orderItems);
                            setProductImage("");
                            setProductImageUrl("");
                            setProductName("");
                            setProductQuantity(1);
                            setProductPrice(0);
                            setProductVariation("");
                            setProductImagev("");
                            setProductNote("");
                            setProductSelect("");
                            setProductExist(false);
                          } else {
                            toast.error(
                              "Some fields are mandatory please check"
                            );
                          }
                        }}
                        className="border border-black text-black text-xs  font-bold px-6 py-2 rounded-full mx-1"
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <div className="font-bold ">Items</div>

                    <div>
                      {orderItems?.map((item, index) => (
                        <div
                          key={index}
                          className="flex flex-row my-2 items-center border-b py-3"
                        >
                          <div className="mx-1">
                            <div
                              onClick={() => {
                                setOrderItems((prevItems) =>
                                  prevItems.filter(
                                    (_, indexToRemove) =>
                                      index !== indexToRemove
                                  )
                                );
                              }}
                              className="mx-1 delete-class cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                />
                              </svg>
                            </div>
                          </div>
                          {item.product_image ? (
                            <img
                              src={URL.createObjectURL(item.product_image)}
                              alt={item.product_name}
                              className="w-10 h-10 mx-1"
                            />
                          ) : (
                            <img
                              src={item.product_image_url}
                              alt={item.product_name}
                              className="w-10 h-10 mx-1"
                            />
                          )}
                          <div className="mx-1 flex-1">
                            {/* Replace with the appropriate item properties you want to display */}
                            <p className="text-sm font-bold">
                              {item.product_quantity} X {item.product_name}
                            </p>
                            <p className="text-sm">
                              {parseFloat(item.product_price).toFixed(2)}
                            </p>
                            <p className="text-sm whitespace-pre-line">
                              {item.product_variation}{" "}
                            </p>
                            <p className="text-sm text-danger whitespace-pre-line">
                              {item.product_note}{" "}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAddOrder(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                var check = true;
                setOrderTypeError("");
                setOrderFromError("");
                setStoreSelectError("");
                setDateSelectError("");
                setOrderNumberError("");
                setTotalAmountError("");

                setClientNameError("");
                setClientPhoneError("");
                setClientEmailError("");
                // setShippingAddressError("");
                setClientExistError("");

                setFullAddressError("");
                setCountryAddressError("");
                setCityAddressError("");
                setStateAddressError("");
                setZipCodeAddressError("");
                setLocationXAddressError("");
                setLocationYAddressError("");

                if (orderType === "") {
                  setOrderTypeError("This field is required.");
                  check = false;
                }
                if (orderFrom === "") {
                  setOrderFromError("This field is required.");
                  check = false;
                }
                if (storeSelect === "" || storeSelect.value === "") {
                  setStoreSelectError("This field is required.");
                  check = false;
                }
                if (dateSelect === "") {
                  setDateSelectError("This field is required.");
                  check = false;
                }
                if (orderNumber === "") {
                  setOrderNumberError("This field is required.");
                  check = false;
                }
                if (totalAmount === "") {
                  setTotalAmountError("This field is required.");
                  check = false;
                } else {
                  if (isNaN(Number(totalAmount)) || Number(totalAmount) <= 0) {
                    setTotalAmountError(
                      "The value of this field is not a positive number."
                    );
                    check = false;
                  }
                }

                if (
                  clientExist === true &&
                  (orderClient === "" || orderClient.value === "")
                ) {
                  setClientExistError("This field is required.");
                  check = false;
                }

                if (orderItems.length === 0) {
                  toast.error("Items Order is required.");
                  check = false;
                }

                if (clientName === "") {
                  setClientNameError("This field is required.");
                  check = false;
                }
                if (clientEmail === "") {
                  setClientEmailError("This field is required.");
                  check = false;
                }
                // if (clientPhone === "") {
                //   setClientPhoneError("This field is required.");
                //   check = false;
                // }

                if (fullAddress === "") {
                  setFullAddressError("This field is required.");
                  check = false;
                }

                if (check) {
                  setEventType("add");
                  setIsAddOrder(true);
                } else {
                  toast.error("Some fields are mandatory please check");
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAddOrder}
          message={
            eventType === "cancel"
              ? "Are you sure you want cancel this information ?"
              : "Are you sure you want add this order ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setOrderFrom("Morocco");
              setOrderFromError("");
              setOrderType("Normal");
              setOrderTypeError("");

              setStoreSelect("");
              setStoreSelectError("");
              setDateSelect("");
              setDateSelectError("");
              setOrderNumber("");
              setOrderNumberError("");
              setTotalAmount("");
              setTotalAmountError("");

              setClientName("");
              setClientNameError("");
              setClientPhone("");
              setClientPhoneError("");
              setClientEmail("");
              setClientEmailError("");
              setShippingAddress("");
              setShippingAddressError("");
              setClientExist(false);
              setClientExistError("");

              setProductExist(false);
              setProductSelect("");
              setProductSelectError("");
              setVariationSelect("");
              setVariationSelectError("");
              setVariationList([]);

              setFullAddress("");
              setFullAddressError("");
              setCountryAddress("");
              setCountryAddressError("");
              setCityAddress("");
              setCityAddressError("");
              setStateAddress("");
              setStateAddressError("");
              setZipCodeAddress("");
              setZipCodeAddressError("");
              setLocationXAddress("");
              setLocationXAddressError("");
              setLocationYAddress("");
              setLocationYAddressError("");
              setSpecializedNote("");
              setSpecializedNoteError("");

              setIsAddOrder(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                addNewOrder({
                  order_type: orderType,
                  order_from: orderFrom,
                  store: storeSelect.value,
                  order_number: orderNumber,
                  date: dateSelect,
                  client_name: clientName ?? "",
                  client_phone: clientPhone ?? "",
                  client_email: clientEmail ?? "",
                  full_address: fullAddress ?? "",
                  location_x: locationXAddress ?? "",
                  location_y: locationYAddress ?? "",
                  city: cityAddress ?? "",
                  state: stateAddress ?? "",
                  zip_code: zipCodeAddress ?? "",
                  country: countryAddress ?? "",
                  total_amount: totalAmount,
                  items: orderItems,
                  client: clientExist ? orderClient.value : "",
                  specialized_note: specializedNote ?? "",
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAddOrder(false);
            }
          }}
          onCancel={() => {
            setIsAddOrder(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
      </div>
    </DefaultLayout>
  );
}

export default AddOrderScreen;
